/**
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  LOGOUT,
} from './actions';
import {
  combineReducers,
} from 'redux';

const appReducer = combineReducers({
  none: () => { return {}; }, // @TODO: need redux?
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
