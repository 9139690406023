/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '75%',
    flex: 1,
  },
  listItem: {
    paddingBottom: 8,
  },
  noMargin: {
    margin: 0,
  },
  floatLeft: {
    float: 'left',
    textAlign: 'left',
    paddingRight: '25%',
  },
  floatRight: {
    float: 'right',
    textAlign: 'right',
    paddingLeft: '25%',
  },
  downloadLinks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  storeLink: {
    margin: 8,
  },
  dialogText: {
    color: Globals.colors.dark,
    fontSize: '1rem',
  },
  dialogLink: {
    color: Globals.colors.accent,
  },
};

export default styles;
