/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Globals from '../../Globals';
import React from 'react';
import ReactGA from 'react-ga';
import commonStyles from '../../commonStyles';
import styles from './styles';

const Home = (props) => {
  const handleClick = (store) => {
    ReactGA.event({
      category: 'Download button clicked',
      action: `${store} visited`,
    });
  };

  return (
    <div style={styles.container}>
      <div style={commonStyles.maxWidthContainer}>
        <div style={{...styles.section, ...styles.floatLeft}}>
          <h1>{"Tired of dating apps that don't work?"}</h1>
          <ul style={styles.noMargin}>
            <li style={styles.listItem}>Enjoy a truly <em><strong>one-of-a-kind</strong></em> dating experience!</li>
            <li style={styles.listItem}>Find a <em><strong>real</strong></em>{" relationship.  No hooking up here; when we say we'll show you "}<em><strong>the one</strong></em>, we mean it!</li>
            <li style={styles.listItem}>The <em><strong>most highly curated</strong></em> and <em><strong>individual</strong></em> profiles of any dating app.</li>
            <li style={styles.listItem}>{`"I've been praying for The One for `}<em><strong>years</strong></em>{`!" -#1 fan of The One`}</li>
            <li style={styles.listItem}>Discover <em><strong>The One</strong></em> today!</li>
          </ul>
        </div>
        <div style={{...styles.section, ...styles.floatRight}}>
          <h1>Download <strong>The One</strong>!</h1>
          <div style={styles.downloadLinks}>
            <a
              style={styles.storeLink}
              onClick={() => handleClick('app store')}
              href={Globals.appStoreUrl}
            >
              <img alt='iOS download' src='/img/apple_app_store_icon.svg' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
