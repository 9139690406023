/**
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import {
  createStore,
} from 'redux';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  let store = createStore(persistedReducer, {});
  let persistor = persistStore(store);

  // @NOTE: uncomment when store needs reset
  // persistor.purge();

  return {
    store,
    persistor,
  };
};

export default configureStore;
