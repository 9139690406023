/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import App from './components/App';
import Globals from './Globals';
import {
  PersistGate,
} from 'redux-persist/integration/react';
import {
  Provider,
} from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './Store';
import {
  createGlobalStyle,
} from 'styled-components';

// @TODO: background: linear-gradient(330deg, #e05252 0%, #99e052 25%, #52e0e0 50%, #9952e0 75%, #e05252 100%);
// @TODO: is overflow-x hidden a problem for html tag? would rather fix the root issue
const GlobalStyle = createGlobalStyle`
  html {
    overflow-x: hidden;
  }

  body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 100;
    line-height: 1.4rem;
    color: ${Globals.colors.light};
    font-size: 22px;
    body: width;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3 {
    color: ${Globals.colors.light};
    font-weight: 100;
  }

  h1 {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }

  h4,
  h5,
  h6 {
    color: ${Globals.colors.light};
    font-weight: 400;
  }

  a {
    color: ${Globals.colors.light};
  }

  p {
    font-size: 1.3rem;
  }

  .expansion-panels-wrapper p {
    margin-top: 0;
  }
`;

const reduxStuff = {...configureStore()};
const rootJsx = (
  <Provider store={reduxStuff.store}>
    <PersistGate loading={null} persistor={reduxStuff.persistor}>
      <GlobalStyle />
      <App />
    </PersistGate>
  </Provider>
);

ReactDOM.render(rootJsx, document.getElementById('root'));
