/**
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

// action types
export const LOGOUT = 'LOGOUT';

export function logout() {
  localStorage.clear();

  return {
    type: LOGOUT,
  };
}
