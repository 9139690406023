/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    backgroundColor: Globals.appName === 'wrinkly' ? Globals.colors.primaryExtraDark : Globals.colors.primary,
    color: Globals.colors.white,
  },
  footerInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    textDecoration: 'none',
    color: 'inherit',
  },
  footerImage: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
};

export default styles;
