/*
 * Ryan O'Dowd
 * 2018-11-29
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

const CONTENT_PADDING = 64;

const commonStyles = {
  maxWidthContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(100% - ${2 * CONTENT_PADDING}px)`,
    maxWidth: 1000,
    minHeight: `calc(100vh - 84px - 44px - ${2 * CONTENT_PADDING}px)`, // @TODO: dynamic, not hardcoded
    padding: CONTENT_PADDING,
  },
  expanding: {
    flexGrow: 1,
  },
};

export default commonStyles;
