/*
 * Ryan O'Dowd
 * 2019-05-28
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const Footer = (props) => {
  return (
    // @TODO: footer should be a minimum of stuck to the bottom of the window (shouldn't be any space after it)
    <footer style={styles.container}>
      <a
        style={{...styles.footerInner, ...(props.backgroundColor ? {backgroundColor: props.backgroundColor} : {})}}
        href='https://oakwoodsc.com'
      >
        <img style={styles.footerImage} src={require('./assets/oakwood_logo_small.png')} alt='oakwood logo' />
        Powered by Oakwood Software Consulting, Inc. © {dateFnsFormat(new Date(), 'yyyy')}
      </a>
    </footer>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
};

export default Footer;
