/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    backgroundImage: 'url("/img/the_one_bg.png")',
    backgroundSize: 'cover',
    minHeight: '100vh',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'calc(100vh - 44px - 84px)', // @TODO: header and footer heights...make dynamic
    margin: 'auto',
  },
};

export default styles;
