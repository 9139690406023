/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const ENV = window.document.location.hostname === 'localhost' || window.document.location.hostname.startsWith('.dev') ? 'dev' : 'prod';

const Globals = {
  company: process.env.REACT_APP_COMPANY,
  companyIsNabc: process.env.REACT_APP_COMPANY === 'nabc',
  env: ENV,

  colors: {
    primary: '#e91e63',
    accent: '#7c4dff',
    error: '#f00', // @TODO: better error color

    light: '#fefefe',
    lightGray: '#cccccc',
    mediumGray: '#5e6472',
    darkGray: '#3d3d3d',
    dark: '#212121',
  },

  themePrimary: {
    50: '#fce4ec',
    100: '#f8bcd0',
    200: '#f48fb1',
    300: '#f06292',
    400: '#ec407a',
    500: '#e91e63',
    600: '#e61a5b',
    700: '#e31651',
    800: '#df1247',
    900: '#d90a35',
    A100: '#ffffff',
    A200: '#ffd0d8',
    A400: '#ff9dae',
    A700: '#ff8498',
    'contrastDefaultColor': 'light',
  },
  themeAccent: {
    50: '#efeaff',
    100: '#d8caff',
    200: '#bea6ff',
    300: '#a382ff',
    400: '#9068ff',
    500: '#7c4dff',
    600: '#7446ff',
    700: '#693dff',
    800: '#5f34ff',
    900: '#4c25ff',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#dad3ff',
    A700: '#c4b9ff',
    'contrastDefaultColor': 'light',
  },

  appStoreUrl: 'https://itunes.apple.com/us/app/id1441223102?mt=8&ls=1',
};

export default Globals;
