/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Footer from '../OakFooter';
import Globals from '../../Globals';
import Header from '../Header';
import Home from '../Home';
import React from 'react';
import ReactGA from 'react-ga';
import {
  StyleRoot,
} from 'radium';
import styles from './styles';

ReactGA.initialize('UA-52056737-11');

const theme = createMuiTheme({
  palette: {
    primary: Globals.themePrimary,
    secondary: Globals.themeAccent,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.pageview(window.location.href);
  }

  render() {
    return (
      <StyleRoot>
        <MuiThemeProvider theme={theme}>
          <div style={styles.container}>
            <BrowserRouter>
              <React.Fragment>
                <Header />
                <div style={styles.innerContainer}>
                  <Switch>
                    <Route path='/' component={Home} />
                  </Switch>
                </div>
              </React.Fragment>
            </BrowserRouter>
            <Footer />
          </div>
        </MuiThemeProvider>
      </StyleRoot>
    );
  }
}

export default App;
