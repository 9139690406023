/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const DEV_NOTICE_PADDING = 4;
const DEV_NOTICE_FONT_SIZE = 12;
const IMG_SIZE = 64;

const styles = {
  container: {
  },
  devIndicator: { // @TODO: abstract devIndicator to an npm module // @TODO: is it dangerous to have this?
    backgroundColor: '#07f',
    color: '#fff',
    padding: DEV_NOTICE_PADDING,
    fontSize: DEV_NOTICE_FONT_SIZE,
    fontWeight: '600',
    textAlign: 'center',
    width: '100vw',
    position: 'fixed',
    zIndex: 1111,
  },
  devSpacer: {
    minHeight: 2 * DEV_NOTICE_PADDING + DEV_NOTICE_FONT_SIZE + 10, // @TODO: 10 is magic
  },
  closeDevIndicatorButton: {
    float: 'left',
    position: 'absolute',
    fontSize: DEV_NOTICE_FONT_SIZE,
    cursor: 'pointer',
    left: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${Globals.colors.dark}44`,
    padding: 10,
    color: Globals.colors.light,
    width: '100%',
  },
  headerLogo: {
    width: IMG_SIZE,
    height: IMG_SIZE,
  },
  headerText: {
    fontFamily: 'Montserrat',
    fontWeight: '800',
    letterSpacing: 16,
    margin: 0,
    marginLeft: 32,
  },
};

export default styles;
