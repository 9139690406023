/*
 * Ryan O'Dowd
 * 2019-05-11
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import CloseIcon from 'mdi-react/CloseIcon';
import Globals from '../../Globals';
import React from 'react';
import styles from './styles';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDevIndicator: Globals.env === 'dev',
    };
  }

  render() {
    return (
      <div style={styles.container}>
        {this.state.showDevIndicator &&
          <React.Fragment>
            <div style={styles.devSpacer}>
              <div style={styles.devIndicator}>
                DEV ENV: changes here will not affect production data <CloseIcon style={styles.closeDevIndicatorButton} onClick={() => this.setState({showDevIndicator: false})} />
              </div>
            </div>
          </React.Fragment>
        }
        <header style={styles.header}>
          <img style={styles.headerLogo} src='/img/header_logo.png' alt='logo' />
          <h1 style={styles.headerText}>THE ONE</h1>
        </header>
      </div>
    );
  }
}

export default Header;
